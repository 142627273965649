defineDs('DanskeSpil/Domain/MarketingSpots/Scripts/SpotAutoTagging',
  [
    'Shared/Framework/Ensighten/Scripts/Ensighten',
    'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils',
  ],
  function (Ensighten, DOMUtils) {
    // click on promo event
    DOMUtils.addEventListeners(document.querySelectorAll('.js-trackable-link'), 'click', (event) => {
      var $parent = event.target.closest('.js-trackable');
      promoClickTracking($parent);
    });

    var promoClickTracking = function (promoElement) {
      var $elementsList = document.querySelectorAll('.js-trackable');
      var lastItemVerticalPos = -1;
      var currentItemVerticalPos = 0;
      var currentRow = 0;
      var currentItemPos = 1;
      var dataTrackingPos;

      $elementsList.forEach((element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        currentItemVerticalPos = element.offsetTop;

        if (currentItemVerticalPos === lastItemVerticalPos) {
          currentItemPos++;
        } else {
          if (lastItemVerticalPos !== -1) currentRow++;
          currentItemPos = 1;
          lastItemVerticalPos = currentItemVerticalPos;
        }
        if (element === promoElement) {
          dataTrackingPos = 'r' + (currentRow < 10 ? '0' : '') + currentRow + '_e' + (currentItemPos < 10 ? '0' : '') + currentItemPos;

          var promotions = [
            {
              name: element.dataset.trackingBrand,
              creative: element.dataset.trackingName,
              position: dataTrackingPos
            }
          ];

          Ensighten.pushPromoClick(promotions);
        }
      });
    };

    // Scroll into viewport detection
    var usedEventArray = [];

    const isScrolledIntoView = (element) => {
      const rect = element.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    };

    const areElementsInView = (elements) => {
      var $elementList = document.querySelectorAll(elements);
      var lastItemVerticalPos = -1;
      var currentItemVerticalPos = 0;
      var currentRow = 0;
      var currentItemPos = 1;
      var dataTrackingPos;

      $elementList.forEach((element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        var key = element.dataset.trackingBrand + element.dataset.trackingName;

        currentItemVerticalPos = element.offsetTop;

        if (currentItemVerticalPos === lastItemVerticalPos) {
          currentItemPos++;
        } else {
          if (lastItemVerticalPos !== -1) currentRow++;
          currentItemPos = 1;
          lastItemVerticalPos = currentItemVerticalPos;
        }

        if (isScrolledIntoView(element)) {
          if (usedEventArray.indexOf(key) < 0) {
            usedEventArray.push(key);

            dataTrackingPos = 'r' + (currentRow < 10 ? '0' : '') + currentRow + '_e' + (currentItemPos < 10 ? '0' : '') + currentItemPos;

            var promotions = [
              {
                name: element.dataset.trackingBrand,
                creative: element.dataset.trackingName,
                position: dataTrackingPos
              }
            ];

            Ensighten.pushPromoView(promotions);
          }
        }
      });
    };

    document.addEventListener('scroll', () => {
      areElementsInView('.js-trackable');
    });

    var buildTrackingLink = function () {
      // Still in use per 6/8-2019 (used intensely on the "old" frontpage)

      var $elementList = document.querySelectorAll('.js-trackable');
      var lastItemVerticalPos = -1;
      var currentItemVerticalPos = 0;
      var currentRow = 0;
      var currentItemPos = 1;
      var linkUrl;
      var dataTrackingKey;
      var dataTrackingPos;

      $elementList.forEach((element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

        currentItemVerticalPos = element.offsetTop;

        if (currentItemVerticalPos == lastItemVerticalPos) {
          currentItemPos++;
        } else {
          if (lastItemVerticalPos != -1) currentRow++;
          currentItemPos = 1;
          lastItemVerticalPos = currentItemVerticalPos;
        }

        dataTrackingPos = 'r' + (currentRow < 10 ? '0' : '') + currentRow + '_e' + (currentItemPos < 10 ? '0' : '') + currentItemPos;
        dataTrackingKey = dataTrackingPos + '__' + element.getAttribute('data-tracking-key');

        var sublinkWithParams = '';

        if (dataTrackingKey) {

          var $linkItem = element.querySelector('.js-trackable-link');
          linkUrl = $linkItem?.href;

          if (!linkUrl) return;

          if (linkUrl.indexOf('?') > -1) {

            var splitLink = linkUrl.split('?');
            var mainLink = splitLink[0];
            var subLink = splitLink[1];

            if (linkUrl.indexOf('intcmp') > -1) {

              var subLinkParam = subLink.split('&');

              for (var i = 0; i < subLinkParam.length; i++) {
                if (subLinkParam[i].indexOf('intcmp') > -1) {
                  if (subLinkParam[i].indexOf('#') > -1) {
                    var hashParam = subLinkParam[i].split('#');
                    hashParam = hashParam[1];
                    subLinkParam[i] = 'intcmp=' + dataTrackingKey + '#' + hashParam;
                  } else {
                    subLinkParam[i] = 'intcmp=' + dataTrackingKey;
                  }
                }
                sublinkWithParams += subLinkParam[i] + (i == subLinkParam.length - 1 ? '' : '&');
              }
            } else {
              sublinkWithParams = subLink + '&intcmp=' + dataTrackingKey;
            }
            $linkItem.href = mainLink + '?' + sublinkWithParams;

          } else {

            $linkItem.setAttribute('href', linkUrl + '?intcmp=' + dataTrackingKey); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
          }
        }
      });
    };

    var init = function () {
      buildTrackingLink();
    };

    init();
  });
