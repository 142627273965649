defineDs('DanskeSpil/Domain/FrontPage/Scripts/FocusSpot', function () {
  document.addEventListener('DOMContentLoaded', function () {

    // Elements:
    var responsiveImages = document.querySelectorAll('.js-responsive-image, .js-feed-error-img');

    // Functions:
    var init = function () {
      responsiveImages.forEach(function (image) {
        image.parentNode.style.overflow = 'hidden';

        image.addEventListener('load', function () {
          image.classList.remove('responsive-image-hidden');
        });
      });
    };

    init();

  });
});
