defineDs('DanskeSpil/Framework/Counter/Scripts/FlipClock', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
],
function (Utils) {
  // This should be deprecated as we have DanskeSpil/Framework/FlipClock/Scripts/FlipClock, but this is still used in BundleConfigs\DanskeSpil\region-frontpage.js

  var init = function () {
    $DS('.js-flip-clock').each(function () {
      Utils.logDeprecated('Framework/Counter/Scripts/FlipClock.js - .js-flip-clock - DOM element found'); // Added by EKJEAR 2024-08-15
      var $container = $DS(this);
      var startDate = $container.data('start-date');
      var time = clockTime(startDate);

      if (!time || isNaN(time) || time >= 8639999 || time <= 0) { // if valid
        $container.hide();
        return;
      }

      Utils.logDeprecated('Framework/Counter/Scripts/FlipClock.js - .js-flip-clock found with valid start time'); // Added by MIPE 2022-08-08

      var customClockFace = 'DailyCounter';
      if (time <= 3600) {
        customClockFace = 'MinuteCounter';
      } if (time > 3600 && time < 3600 * 24) {
        customClockFace = ' ';
      }

      var clock = $container.FlipClock({
        clockFace: customClockFace
      });
      clock.setTime(time);
      clock.setCountdown(true);

      $container.parentsUntil('.row').each(function () {
        var $this = $DS(this);
        if ($this.hasClass('size-single')) {
          setShortLabel($container);
          $container.addClass('clock-size-single');
        } else if ($this.hasClass('size-double')) {
          setShortLabel($container);
          $container.addClass('clock-size-double');
        } else if ($this.hasClass('size-triple')) {
          setLabel($container);
          $container.addClass('clock-size-triple');
        } else if ($this.hasClass('size-full')) {
          setLabel($container);
          $container.addClass('clock-size-full');
        }
      });
    });
  };

  var clockTime = function (dateTime) {
    if (typeof dateTime === 'undefined' || dateTime === '') {
      return 0;
    }

    // var startDate = new Date();
    var year = dateTime.substring(0, 4);
    var month = dateTime.substring(4, 6);
    var day = dateTime.substring(6, 8);
    var hours = dateTime.substring(9, 11);
    var minutes = dateTime.substring(12, 14);
    var seconds = dateTime.substring(15, 17);

    // startDate.setFullYear(year, month, day);
    var game = new Date(year, month - 1, day, hours, minutes, seconds);
    var today = new Date();
    // var dif = Math.floor(Math.abs(game - today) / 1000); what is the abs for? -jimr
    var dif = Math.floor((game - today) / 1000);
    return dif;
  };

  var setLabel = function ($container) {
    $container.find('.days .flip-clock-label').text('Dage');
    $container.find('.hours .flip-clock-label').text('Timer');
    $container.find('.minutes .flip-clock-label').text('Min');
    $container.find('.seconds .flip-clock-label').text('Sek');
  };

  var setShortLabel = function ($container) {
    $container.find('.days .flip-clock-label').text('D');
    $container.find('.hours .flip-clock-label').text('T');
    $container.find('.minutes .flip-clock-label').text('M');
    $container.find('.seconds .flip-clock-label').text('S');
  };


  $DS(window.document).ready(function () {
    init();
  });
});
