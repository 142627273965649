defineDs('DanskeSpil/Domain/Oddset/Scripts/OddsetFeed', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (Utils) {

  var feedServerAjax = function (requestPath) {
    var url = DS.Config.FEEDSERVER_URL + requestPath + (requestPath.indexOf('?') > -1 ? '&token=' : '?token=') + DS.Config.FEEDSERVER_TOKEN;

    var options = {
      type: 'GET',
      url: url,
      contentType: 'application/json; charset=utf-8',
      dataType: 'json'
    };

    return $DS.ajax(options);
  };

  var init = function () {
    // Still in use per 6/8-2019 (used intensely on the "old" frontpage)

    $DS('.js-oddset-feed').each(function () {
      var $spot = $DS(this);

      createMatch($spot);

      setInterval(function () {
        createMatch($spot);
      }, 60000);

      setInterval(function () {
        var matches = $spot.data('matches');
        var index = $spot.data('index');
        if (typeof matches === 'object' && typeof index === 'number') {
          displayMatch($spot);
        }
      }, 10000);
    });

  };

  var createMatch = function ($spot) {
    var sportsId = $spot.data('sports-id');
    var ligaId = $spot.data('liga-id');
    var matchId = $spot.data('match-id');
    var number = $spot.data('number');

    if (matchId !== 0) {
      var matchFeed = match(matchId);
      matchFeed.success(function (data) {
        if (!data || data.length < 1) {
          console.log('Oddset feed with [eventId: ' + matchId + '] has no data');
          return;
        }
        var matches = [];

        var market = sanitizeMarketData(data.market || null);
        if (market) {
          var match = populateMatch(market);
          match.id = matchId;
          matches.push(match);
        }
        var wrapper = { matches: matches };
        $spot.data('matches', wrapper);
        $spot.data('index', 0);
        displayMatch($spot);
      });


    } else if (typeof sportsId !== 'number') {
      return;

    } else {
      var feed = oddset(sportsId, ligaId, number);
      feed.success(function (data) {
        if (typeof data == 'undefined' || !data) {
          console.log('Oddset feed with [sportId: ' + sportsId + '], and [ligaId: ' + ligaId + '] has no data');
          return;
        }

        var matches = [];
        var market;
        var match;

        if (data.length != undefined) {
          for (var i = 0; i < data.length; i++) {
            market = sanitizeMarketData(data[i].market || null);

            if (market) {
              match = populateMatch(market);
              match.id = data[i].id;
              matches[matches.length] = match;
            }
          }
        } else {
          market = sanitizeMarketData(data.market || null);

          if (market) {
            match = populateMatch(market);
            match.id = data.id;
            matches[matches.length] = match;
          }
        }
        var wrapper = { matches: matches };
        $spot.data('matches', wrapper);
        $spot.data('index', 0);
        displayMatch($spot);
      });
    }
  };

  var populateMatch = function (market) {
    var outcomes = market.outcome;
    var match = {};

    /* Of all outcomes has either resultType 'H' (hjemmehold), 'A' (udehold) or 'D'/'L' (uafgjort), we will rely on this */
    var useResultType = (outcomes.length === outcomes.filter(function (o) {
      return $DS.inArray(o.resultType, ['H', 'A', 'D', 'L']) > -1;
    }).length);

    for (var j = 0; j < outcomes.length; j++) {

      if (useResultType) {

        /* Using resultType to determine the order */
        switch (outcomes[j].resultType) {
        case 'H':
          match.home = outcomes[j].name;
          match.odds1 = outcomes[j].oddsDecimal;
          break;
        case 'L':
        case 'D':
          match.oddsX = outcomes[j].oddsDecimal;
          break;
        case 'A':
          match.away = outcomes[j].name;
          match.odds2 = outcomes[j].oddsDecimal;
        }

      } else {

        /* Using index to determine the order */
        if (j === 0) {
          match.home = outcomes[j].name;
          match.odds1 = outcomes[j].oddsDecimal;
        } else if (j === 1 && j !== outcomes.length - 1) {
          match.oddsX = outcomes[j].oddsDecimal;
        } else {
          match.away = outcomes[j].name;
          match.odds2 = outcomes[j].oddsDecimal;
        }

        Utils.logError('Cannot use resultType, marketId: ' + (market.id || 'undefined'), 'OddsetFeedError');
      }
    }
    return match;
  };

  var displayMatch = function ($spot) {
    var wrapper = $spot.data('matches');
    var index = $spot.data('index');
    var $linkButton = $spot.parent().find('.js-link-button');

    var campaignFieldId = $linkButton.data('campaign-id');

    if (!wrapper.matches || !wrapper.matches[index]) {
      console.debug('No matches, cannot iterate');
      return;
    }

    $spot.find('.js-teams').html('<span class="js-measuretext">' + wrapper.matches[index].home + '<span class="js-divider"> - </span>' + wrapper.matches[index].away + '</span>');
    $spot.find('.js-scores li:nth-child(1)').html(wrapper.matches[index].odds1.replace('.', ','));
    $spot.find('.js-scores li:nth-child(2)').html(wrapper.matches[index].oddsX.replace('.', ','));
    $spot.find('.js-scores li:last-child()').html(wrapper.matches[index].odds2.replace('.', ','));
    $linkButton.attr('href', DS.Config.ODDSET_EVENT_URL + '/' + wrapper.matches[index].id + (campaignFieldId ? '?intcmp=' + campaignFieldId : ''));

    $spot.parents('.js-default-content').find('.js-default-image').each(function () {
      var $element = $DS(this);
      if ($element.hasClass('is-normal')) {
        $element.removeClass('is-hidden');
      } else if ($element.hasClass('is-default')) {
        $element.removeClass('is-visible');
        $element.addClass('is-hidden');
      }
    });
    $spot.addClass('is-visible');
    textSize($spot);

    index++;
    $spot.data('index', index < wrapper.matches.length ? index : 0);
  };

  var textSize = function ($spot) {
    var $text = $spot.find('.js-measuretext');
    var $box = $spot.parents('.js-measuretext-box');

    var boxHeight = $box.height();

    var fontSize;

    if ($box.innerHeight() === 310) {
      fontSize = Math.ceil(boxHeight / 8);
    } else if ($box.innerHeight() === 330) {
      fontSize = Math.ceil(boxHeight / 7);
    } else {
      fontSize = Math.ceil(boxHeight / 3);
    }

    $spot.find('.js-measuretext').css('font-size', fontSize + 'px');

    if ($text.width() > $box.width() * 0.9) {
      $spot.find('.js-measuretext').addClass('two-lines');
      $spot.find('.js-divider').addClass('two-lines-divider');
      fontSize = Math.ceil(boxHeight / 5);
      if ($box.parent().hasClass('size-single')) {
        fontSize = Math.ceil(boxHeight / 20);
      } else if ($box.parent().hasClass('size-double')) {
        fontSize = Math.ceil(boxHeight / 10);
      } else if ($box.parent().hasClass('size-triple')) {
        fontSize = Math.ceil(boxHeight / 8);
      }
      $spot.find('.js-measuretext').css('font-size', fontSize + 'px');

    } else {
      $spot.find('.js-measuretext').removeClass('two-lines');
      $spot.find('.js-divider').removeClass('two-lines-divider');
    }
    if ($spot.find('.js-measuretext').height() + 10 < $spot.find('.js-oddset-feed').height()) {
      fontSize = Math.ceil(boxHeight / 6);
      $spot.find('.js-measuretext').css('font-size', fontSize + 'px');
    }
    if ($box.parent().hasClass('size-double')) { // to fix error ITU-2061
      if (fontSize > 80) {
        $spot.find('.js-measuretext').css('font-size', '80px');
      }
    }
  };

  var sanitizeMarketData = function (market) {
    /* If data is an array, take first element */
    if (market && Array.isArray(market)) {
      market = market[0] || null;
    }

    /* Return null if market exists and has outcome */
    if (!market || !market.outcome) {
      return null;
    }

    return market;
  };

  var oddset = function (sportId, ligaId, number) {
    return feedServerAjax('sportsbook/topEventsByCriteria.json?onlymain=1&ligaid=' + ligaId + '&sportid=' + sportId + '&antal=' + number);
  };

  var match = function (eventId) {
    return feedServerAjax('sportsbook/geteventob.json?onlymain=1&eventid=' + eventId);
  };

  $DS(window.document).ready(function () {
    if ($DS('body').hasClass('mode-edit')) {
      return;
    }
    init();
  });
});
